<template>
  <div class="modal" id="escrowModal">
    <div class="modal-bg" @click="$emit('close')"></div>

    <ModalError v-if="ui.isError" :error="ui.error" @tryAgain="loadEscrow"></ModalError>


    <ModalSpinner v-if="ui.isLoading"></ModalSpinner>


    <OpenEscrowModal v-if="!ui.isLoading && !ui.isError && escrow === null" 
      :loan="loan" 
      :form="form" 
      :subjectProperty="subjectProperty"
      @escrowOpened="loadEscrow"
    />

    <EscrowDetailsModal v-if="!ui.isLoading && !ui.isError && escrow !== null && !ui.isEditEscrowModalOpen"
                        :escrow="escrow"
                        :form="form"
                        :loan="loan"
                        @docsSubmitted="loadEscrow"
                        @openEditEscrowModal="ui.isEditEscrowModalOpen = true"
    />


    <EditEscrowModal v-if="!ui.isLoading && !ui.isError && escrow !== null && ui.isEditEscrowModalOpen"
                     :loan="loan"
                     :form="form"
                     :loanEscrow="escrow"
                     @escrowUpdated="loadEscrow"
                     @closeEditEscrowModal="ui.isEditEscrowModalOpen = false"
    />

  </div>
</template>

<script>
import ModalSpinner from "../../../components/modal/ModalSpinner";
import OpenEscrowModal from "./OpenEscrowModal";
import EscrowDetailsModal from "./EscrowDetailsModal";
import ModalError from "../../../components/modal/ModalError";
import EditEscrowModal from "./EditEscrowModal";

export default {
  name: "EscrowModal",
  components: {EditEscrowModal, ModalError, EscrowDetailsModal, OpenEscrowModal, ModalSpinner},
  props: {
    loanId: Number,
  },
  data() {
    return {
      ui: {isLoading: false, isError: false, error: '', isEditEscrowModalOpen: false},
      escrow: null,
      form: {
        escrowCompanies: [],
        titleCompanies: [],
        lenders: [],
        states: [],
      },
      loan: {
        id: this.loanId,
        loanNumber: null,
        coborrower: {firstName: null, lastName: null},
        isRefi: true
      },
      subjectProperty: {}
    };
  },
  methods: {
    getsubjectPropertyDetails() {
      this.ui.isLoading = true
      this.ui.isError = false
      this.$http.get(`/api/v1/loans/lendingpad/autofill/borrower/${this.loanId}/subjectproperty`)
        .then(response => {
          this.subjectProperty = response.data.subjectProperty
        })
        .catch(error => {
          this.ui.isError = true
          this.ui.error = this.getFirstErrorFromResponse(error.response)
        })
        .finally(() => {
          this.ui.isLoading = false
          this.loadEscrow()
        })
    },
    loadEscrow() {
      this.ui.isLoading = true
      this.ui.isError = false

      this.$http.get('/api/v1/loans/escrow/view/' + this.loanId)
        .then(response => {
          this.escrow = response.data.escrow
          this.form = response.data.form
          this.loan = response.data.loan
          this.ui.isError = false
        })
        .catch(error => {
          this.ui.isError = true
          this.ui.error = this.getFirstErrorFromResponse(error.response)
        })
        .finally(() => {
          this.ui.isLoading = false
        })
    }
  },
  created() {
    this.getsubjectPropertyDetails()
  }
}
</script>
<style scoped lang="scss">
.escrow-document-wrapper {
  padding-bottom: 20px;
  border-bottom: 1px solid #E7E8E8;
  margin-bottom: 0;
}
</style>
