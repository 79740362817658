<template>
  <div class="modal-block">
    <div class="modal-header">
      <h3>
        Escrow Details
      </h3>
    </div>
    <div class="modal-body">

      <div class="modal-body-container mb-3">
        <div class="row">
          <div class="col-1">
            <v-avatar :username="loan.borrower.firstName + ' ' + loan.borrower.lastName" :size="42" rounded />
          </div>
          <div class="col-10 my-auto">
              <span>
                {{loan.borrower.firstName}} {{loan.borrower.lastName}}
              </span>
            <span class="color-light-grey ps-2" v-if="loan.borrower.email">{{loan.borrower.email}}</span>
          </div>
        </div>
      </div>

      <div class="modal-body-container border-bottom-0 pb-0">

        <ValidationErrorsList class="mt-4" :isError="ui.isError" :error="ui.error" :errors="ui.errors" />

        <div class="escrow-document-wrapper">
          <div class="modal-body-container_title mb-3">
            Send Documents to Escrow
          </div>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="checkPayOff1" v-model="loanEscrow.isPayoff1">
            <label class="form-check-label" for="checkPayOff1">Payoff 1</label>
          </div>
          <div class="form-group full-width mt-3" v-if="loanEscrow.isPayoff1">
            <label>
              Current Mortgage Statement
            </label>
            <FileField
              :multiple="true"
              :files="loanEscrow.payoff1"
              @change="submitFiles('escrow-details-payoff1', 'payoff1')"
              field-id="escrow-details-payoff1"
            />

            <ul class="list-group mt-3" v-if="order.payoff1.docs.length > 0">
              <li v-for="doc in order.payoff1.docs"
                  :key="'payoff1Doc' + doc.id"
                  class="list-group-item d-flex justify-content-between align-items-center"
              >
                {{ doc.clientFilename }}
                <div class="list-group-item-icons d-flex">
                <span
                  class="badge cursor-pointer btn btn-control"
                  @click="openFilePreview('loan_escrow_doc', doc.id, doc.clientFilename)">
                  <img src="@/assets/icons/icon-preview.svg" alt="preview">
                </span>
                  <span class="badge cursor-pointer btn btn-control" @click="deleteDocument(doc.id)">
                  <img src="@/assets/icons/icon-delete.svg" alt="delete">
                </span>
                </div>
              </li>
            </ul>

          </div>
        </div>
        <div class="escrow-document-wrapper">
          <div class="form-check form-switch mt-3">
            <input class="form-check-input" type="checkbox" id="checkPayOff2" v-model="loanEscrow.isPayoff2">
            <label class="form-check-label" for="checkPayOff2">Payoff 2</label>
          </div>
          <div class="mt-3" v-if="loanEscrow.isPayoff2">
            <label>
              Current Mortgage Statement
            </label>
            <FileField
              :multiple="true"
              :files="loanEscrow.payoff2"
              @change="submitFiles('escrow-details-payoff2', 'payoff2')"
              field-id="escrow-details-payoff2"
            />
          </div>
        </div>
        <div class="escrow-document-wrapper">
          <div class="form-check form-switch mt-3">
            <input class="form-check-input" type="checkbox" id="checkCpl" v-model="loanEscrow.isCpl">
            <label class="form-check-label" for="checkCpl">CPL</label>
          </div>
          <div class="mt-3" v-if="loanEscrow.isCpl">
              <div class="form-group full-width mb-3">
                <label for="fieldLender">Lender</label>
                <multiselect v-model="loanEscrow.lender"
                             placeholder="Select Lender"
                             track-by="value"
                             label="label"
                             id="fieldLender"
                             class="full-width"
                             :class="{selected: loanEscrow.lender !== null }"
                             :disabled="true"
                             :options="form.lenders"
                             :searchable="true"
                             :close-on-select="true"
                             :allow-empty="false"
                             :show-labels="false">
                </multiselect>
              </div>
              <div class="form-group full-width">
                <label for="fieldLoanNumber">Loan Number</label>
                <input v-model="loanEscrow.loanNumber"
                       type="text" id="fieldLoanNumber"
                       :class="{'input-filled': loanEscrow.loanNumber !== null }"
                       class="form-control full-width" :readonly="true"
                       placeholder="Loan Number">
              </div>
          </div>
        </div>
        <div class="escrow-document-wrapper">
          <div class="form-check form-switch mt-3">
            <input class="form-check-input" type="checkbox" id="checkWireInstruct" v-model="loanEscrow.isWireInstructions">
            <label class="form-check-label" for="checkWireInstruct">Wire Instructions</label>
          </div>
        </div>
        <div class="escrow-document-wrapper">
          <div class="form-check form-switch mt-3">
            <input class="form-check-input" type="checkbox" id="checkUpdatedHomeowners" v-model="loanEscrow.isHomeownersInsurance">
            <label class="form-check-label" for="checkUpdatedHomeowners">Updated Homeowners Insurance</label>
          </div>
          <div class="mt-3" v-if="loanEscrow.isHomeownersInsurance">
            <label>
              Current Homeowners Insurance
            </label>
            <FileField
              :multiple="true"
              :files="loanEscrow.homeownersInsurance"
              @change="submitFiles('escrow-details-homeowners-insurance', 'homeownersInsurance')"
              field-id="escrow-details-homeowners-insurance"
            />
          </div>
        </div>
        <div class="escrow-document-wrapper">
          <div class="form-check form-switch mt-3">
            <input class="form-check-input" type="checkbox" id="checkBorrowerAuth" v-model="loanEscrow.isBorrowerAuth">
            <label class="form-check-label" for="checkBorrowerAuth">Borrower's Authorization</label>
          </div>
          <div class="mt-3" v-if="loanEscrow.isBorrowerAuth">
            <label>
              Borrower's Authorization Document(s)
            </label>
            <FileField
              :multiple="true"
              :files="loanEscrow.borrowerAuth"
              @change="submitFiles('escrow-details-borrower-auth', 'borrowerAuth')"
              field-id="escrow-details-borrower-auth"
            />
          </div>

        </div>
        <div class="escrow-document-wrapper">
          <div class="form-check form-switch mt-3">
            <input class="form-check-input" type="checkbox" id="checkOtherDocs" v-model="loanEscrow.isOtherDocs">
            <label class="form-check-label" for="checkOtherDocs">Other Documents</label>
          </div>
          <div class="" v-if="loanEscrow.isOtherDocs">
            <div class="form-group col-md-12 mt-3 full-width">
              <label for="fieldNote">
                Note for escrow about other documents you attached
              </label>
              <textarea name=""
                        cols="30"
                        rows="4"
                        id="fieldNote"
                        class="form-control"
                        :class="{'input-filled': loanEscrow.otherDocsNote !== null }"
                        v-model="loanEscrow.otherDocsNote"
              >
            </textarea>
            </div>
            <div class="mt-3">
              <label>
                Documents
              </label>
              <FileField
                :multiple="true"
                :files="loanEscrow.otherDocs"
                @change="submitFiles('escrow-details-other-docs', 'otherDocs')"
                field-id="escrow-details-other-docs"
              />
            </div>
          </div>
        </div>
        <div class="modal-body-message bg-lightblue">
          Once you Submit - we will send all documents to escrow "{{escrow.escrowCompany.name}}" &lt;{{escrow.escrowCompany.email}}&gt;
        </div>
        <div class="modal-body-container mt-5 border-bottom-0">
          <div class="modal-body-container_title">
            Loan Information
            <button class="btn-link btn-link-blue" @click="$emit('openEditEscrowModal')">
              Edit
            </button>
          </div>
            <table class="table">
              <tbody>
              <tr>
                <td>Borrower</td>
                <td>{{loan.borrower.firstName}} {{loan.borrower.lastName}}</td>
              </tr>
              <tr>
                <td>
                  Co-Borrower
                </td>
                <td>{{loan.coborrower.firstName}} {{loan.coborrower.lastName}}</td>
              </tr>
              <tr>
                <td>
                  Exact Vesting
                </td>
                <td>{{escrow.exactVesting}}</td>
              </tr>
              <tr>
                <td>
                  Property Address
                </td>
                <td>{{escrow.address}}<br>{{escrow.city}}, {{escrow.state}} {{escrow.zip}}</td>
              </tr>
              <template v-if="loan.isRefi">
                <tr><td>Estimated Value</td><td>{{escrow.estimatedValue}}</td></tr>
              </template>
              <template v-else>
                <tr><td>Purchase Price</td><td>${{escrow.purchase.price}}</td></tr>
              </template>
              </tbody>
            </table>

          <div class="modal-body-container_title">Realtor</div>
          <table class="table">
            <tbody>
              <tr><td>Selling Agent</td><td>{{escrow.purchase.realtor.sellingAgent}}</td></tr>
              <tr><td>Name</td><td>{{escrow.purchase.realtor.name}}</td></tr>
              <tr><td>Brokerage</td><td>{{escrow.purchase.realtor.brokerage}}</td></tr>
              <tr><td>Phone</td><td>{{escrow.purchase.realtor.phone}}</td></tr>
            </tbody>
          </table>

          <div class="modal-body-container_title">Listing Agent</div>
          <table class="table">
            <tbody>
              <tr><td>Name</td><td>{{escrow.purchase.listingAgent.name}}</td></tr>
              <tr><td>Brokerage</td><td>{{escrow.purchase.listingAgent.brokerage}}</td></tr>
              <tr><td>Phone</td><td>{{escrow.purchase.listingAgent.phone}}</td></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-start">
      <button class="btn btn-primary submit" @click="submitForm">
        <span v-if="!ui.isLoading">Submit Documents</span>
        <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import FileField from "../../../components/form/FileField";
import ValidationErrorsList from "../../../components/form/ValidationErrorsList";


export default {
  name: "EscrowDetailsModal",
  components: {ValidationErrorsList, FileField, Multiselect},
  props: {
    form: Object,
    loan: Object,
    escrow: Object,
  },
  data() {
    return {
      ui: {
        isLoading: false,
        isError: false,
        errors: [],
        error: '',
      },
      order: this.escrow.order,
      loanEscrow: {
        payoff1: [],
        payoff2: [],
        homeownersInsurance: [],
        borrowerAuth: [],
        otherDocs: [],
        otherDocsNote: this.escrow.order.other.note || null,
        lender: this.loan.lender,
        loanNumber: this.loan.loanNumber,
        isWireInstructions: this.escrow.order.wireInstructions.isSelected || false,
        isPayoff1: this.escrow.order.payoff1.isSelected || false,
        isPayoff2: this.escrow.order.payoff2.isSelected || false,
        isCpl: this.escrow.order.cpl.isSelected || false,
        isHomeownersInsurance: this.escrow.order.homeownersInsurance.isSelected || false,
        isBorrowerAuth: this.escrow.order.borrowerAuth.isSelected || false,
        isOtherDocs: this.escrow.order.other.isSelected || false,
      },
    }
  },
  methods: {
    submitFiles(fieldId, files) {
      const fileField = document.getElementById(fieldId);
      let len = fileField.files.length;
      this.loanEscrow[files] = [];
      for (let i = 0; i < len; i++) {
        this.loanEscrow[files].push(fileField.files[i]);
      }
    },
    submitForm() {
      this.ui.isLoading = true

      let fd = new FormData();
      for (let key in this.loanEscrow) {
        let val = this.loanEscrow[key];
        if (Array.isArray(val)) {
          for (let doc of val) {
            fd.append(key + "[]", doc);
          }
        } else {
          fd.append(key, val);
        }
      }

      fd.set('lender', this.loanEscrow.lender?.value);

      this.$http.post('/api/v1/loans/escrow/attach/' + this.loan.id, fd)
        .then(() => {
          this.$emit('docsSubmitted')
        })
        .catch((err) => {
          let {errors, error} = this.getErrorsFromResponse(err.response)
          this.ui.isError = true
          this.ui.error = error
          this.ui.errors = errors
          this.$nextTick(() => {
            this.$el.scrollTop = 0;
          });
        })
        .finally(() => {
          this.ui.isLoading = false
        })
    },
    deleteDocument(id) {
      if (!confirm('Are you sure?')) {
        return;
      }

      this.order.payoff1.docs = this.order.payoff1.docs.filter(doc => doc.id !== id)
      this.order.payoff2.docs = this.order.payoff2.docs.filter(doc => doc.id !== id)
      this.order.borrowerAuth.docs = this.order.borrowerAuth.docs.filter(doc => doc.id !== id)
      this.order.other.docs = this.order.other.docs.filter(doc => doc.id !== id)
      this.order.homeownersInsurance.docs = this.order.homeownersInsurance.docs.filter(doc => doc.id !== id)

      this.$http.delete(`/api/v1/loans/escrow/delete-document/${id}`)
        .then(() => {
          this.$emit('docsSubmitted')
        })
    }
  },
}
</script>
<style scoped lang="scss">
.modal-body::v-deep .multiselect {

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38,111,229,0.5);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__tags {
    padding: 8px 40px 0 8px;
    transition: all .3s;
    &:hover {
      border: 1px solid rgba(38,111,229,0.2);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__select {
    &:before {
      top: 15%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }
  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }
  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}

.escrow-document-wrapper {
  padding-bottom: 20px;
  border-bottom: 1px solid #E7E8E8;
  margin-bottom: 0;
}
</style>
