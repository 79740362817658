<template>
  <div class="modal-block" id="edit-escrow-modal-block">
    <div class="modal-header">
      <div class="d-flex align-items-center cursor-pointer">
        <div class="btn btn-control me-3 cursor-pointer" @click="$emit('closeEditEscrowModal')">
          <img src="@/assets/icons/bold-icon previous.svg" alt="">
        </div>
        <h3 class="m-0">Edit Escrow Order</h3>
      </div>
    </div>
    <div class="modal-body">
      <div class="modal-body-container">
        <h5 class="mb-4">Subject Property Address</h5>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group mb-3 full-width">
              <label for="loan_escrow_address" class="required">Address</label>
              <input type="text" id="loan_escrow_address" v-model="escrow.data.address" required="required"
                     class="form-control full-width">
            </div>
          </div>
          <div class="col-md-4 pe-0">
            <div class="form-group">
              <label for="loan_escrow_city" class="required">City</label>
              <input type="text" id="loan_escrow_city" v-model="escrow.data.city" required="required"
                     class="form-control">
            </div>
          </div>
          <div class="col-md-4 pe-0">
            <div class="form-group">
              <label class="required" for="loan_escrow_state">State</label>
              <multiselect v-model="escrow.data.state"
                           placeholder="Select State"
                           id="loan_escrow_state"
                           class="full-width"
                           :options="form.states"
                           :searchable="true"
                           :close-on-select="true"
                           :allow-empty="false"
                           :show-labels="false">
              </multiselect>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="loan_escrow_zip" class="required">Zip</label>
              <input type="text"
                     id="loan_escrow_zip"
                     v-model="escrow.data.zip"
                     required="required"
                     class="form-control">
            </div>
          </div>
        </div>
      </div>


      <div class="modal-body-container" v-if="!loan.isRefi">
        <div class="form-group mb-3">
          <label for="loan_escrow_purchase_price" class="required">Purchase Price</label>
          <input type="number"
                 id="loan_escrow_purchase_price"
                 v-model="escrow.data.purchase.price"
                 required="required"
                 class="form-control">
        </div>
        <h5 class="mb-4">Realtor</h5>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-3 full-width"><label for="loan_escrow_realtor_sellingAgent" class="required">Selling
              agent</label><input type="text" id="loan_escrow_realtor_sellingAgent"
                                  v-model="escrow.data.purchase.realtor.sellingAgent" required="required"
                                  class="form-control full-width"></div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3 full-width"><label for="loan_escrow_realtor_name"
                                                           class="required">Name</label><input type="text"
                                                                                               id="loan_escrow_realtor_name"
                                                                                               v-model="escrow.data.purchase.realtor.name"
                                                                                               required="required"
                                                                                               class="form-control full-width">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-3 full-width"><label for="loan_escrow_realtor_brokerage" class="required">Brokerage</label><input
              type="text" id="loan_escrow_realtor_brokerage" v-model="escrow.data.purchase.realtor.brokerage"
              required="required" class="form-control full-width"></div>

          </div>
          <div class="col-md-6">
            <div class="form-group mb-3 full-width"><label for="loan_escrow_realtor_phone"
                                                           class="required">Phone</label><input type="text"
                                                                                                id="loan_escrow_realtor_phone"
                                                                                                v-model="escrow.data.purchase.realtor.phone"
                                                                                                required="required"
                                                                                                class="phone-mask-field form-control full-width"
                                                                                                maxlength="12"></div>
          </div>
        </div>

        <h5 class="mb-4">Listing agent</h5>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group full-width"><label for="loan_escrow_listingAgent_name">Name</label><input type="text"
                                                                                                             id="loan_escrow_listingAgent_name"
                                                                                                             v-model="escrow.data.purchase.listingAgent.name"
                                                                                                             class="form-control full-width">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group full-width"><label for="loan_escrow_listingAgent_brokerage">Brokerage</label><input
              type="text" id="loan_escrow_listingAgent_brokerage" v-model="escrow.data.purchase.listingAgent.brokerage"
              class="form-control full-width"></div>
          </div>
          <div class="col-md-4">
            <div class="form-group full-width"><label for="loan_escrow_listingAgent_phone">Phone</label><input
              type="text" id="loan_escrow_listingAgent_phone" v-model="escrow.data.purchase.listingAgent.phone"
              class="phone-mask-field form-control full-width" maxlength="12"></div>
          </div>
        </div>
      </div>

      <div class="modal-body-container border-bottom-0 mb-0" v-if="loan.isRefi">
        <div class="form-group mb-3">
          <label for="loan_escrow_estimated_value" class="required">Estimated Value</label>
          <input type="number"
                 id="loan_escrow_estimated_value"
                 v-model="escrow.data.refi.estimatedValue"
                 required="required"
                 class="form-control">
        </div>
      </div>

      <div class="modal-body-container border-bottom-0">
        <div class="form-group full-width">
          <label for="loan_escrow_exact_vesting" class="required full-width">Exact Vesting</label>
          <input type="text" id="loan_escrow_exact_vesting" v-model="escrow.data.exactVesting" required="required"
                 class="form-control">
        </div>
      </div>

      <ValidationErrorsList :isError="ui.form.isError" :error="ui.form.error" :errors="ui.form.errors"/>

    </div>
    <div class="modal-footer d-flex justify-content-start">
      <button class="btn btn-primary submit" @click="submitForm">
        <span v-if="ui.form.isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span v-if="!ui.form.isLoading">Submit</span>
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ValidationErrorsList from "../../../components/form/ValidationErrorsList";

export default {
  name: "EditEscrowModal",
  components: {ValidationErrorsList, Multiselect},
  props: {loan: Object, form: Object, loanEscrow: Object},
  data() {
    return {
      escrow: {
        data: {
          isRefi: this.loan.isRefi,
          loanNumber: this.loan.loanNumber,
          address: this.loanEscrow.address,
          city: this.loanEscrow.city,
          state: this.loanEscrow.state,
          zip: this.loanEscrow.zip,
          refi: this.loanEscrow.refi,
          purchase: this.loanEscrow.purchase,
          exactVesting: this.loanEscrow.exactVesting,
        }
      },
      ui: {
        form: {isLoading: false, isSuccess: false, isError: false, errors: [], error: ''}
      }
    }
  },
  methods: {
    submitForm() {
      this.ui.form.errors = []
      this.ui.form.error = ''
      this.ui.form.isError = false
      this.ui.form.isSuccess = false
      this.ui.form.isLoading = true

      this.$http.post('/api/v1/loans/escrow/edit/' + this.loan.id, this.escrow.data)
        .then(() => {
          this.ui.form.isSuccess = true
          this.$emit('escrowUpdated')
        })
        .catch((e) => {
          this.ui.form.isError = true
          let {errors, error} = this.getErrorsFromResponse(e.response)

          this.ui.form.errors = errors;
          this.ui.form.error = error

          this.$nextTick(() => {
            this.$el.scrollTop = 1000 + this.$el.scrollHeight;
          });
        })
        .finally(() => {
          this.ui.form.isLoading = false
        })
    },
  }
}
</script>

<style scoped lang="scss">
.modal-body-container::v-deep .multiselect {

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38,111,229,0.5);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__tags {
    padding: 8px 40px 0 8px;
    transition: all .3s;
    &:hover {
      border: 1px solid rgba(38,111,229,0.2);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__select {
    &:before {
      top: 15%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }
  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }
  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}
</style>
