<template>
  <div class="modal-block">
    <div class="modal-body">
      <div class="modal-body-container border-0">
        <div class="alert alert-danger">
          {{ error }}
        </div>

        <div class="try-again mt-4 text-center">
          <button class="btn btn-primary" @click="$emit('tryAgain')">Try again</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalError",
  props: {
    error: String
  }
}
</script>

<style scoped>

</style>
