<template>
  <div id="open-escrow-modal-block" class="modal-block">
    <div class="modal-header">
      <h3>
        Open Escrow/Title
      </h3>
    </div>
    <div id="modal-body-scroll" class="modal-body">
      <div class="modal-body-container">
        <div v-if="!loan.loanNumber" class="form-group mb-3">
          <label class="required" for="loan_escrow_loanNumber">Loan Number</label>
          <input id="loan_escrow_loanNumber" v-model="escrow.data.loanNumber" class="form-control" required="required"
                 type="text">
        </div>
        <div class="form-group full-width mb-3">
          <label for="fieldEscrowCompany">
            Escrow Company
          </label>
          <multiselect id="fieldEscrowCompany"
                       v-model="escrow.data.escrowCompany"
                       :allow-empty="false"
                       :class="{selected: escrow.data.escrowCompany !== null}"
                       :close-on-select="true"
                       :options="form.escrowCompanies"
                       :searchable="true"
                       :show-labels="false"
                       class="full-width"
                       label="label"
                       placeholder="Select Escrow"
                       track-by="value">
          </multiselect>
        </div>
        <div class="form-group full-width mb-3">
          <label for="fieldTitleCompany">
            Title Company
          </label>
          <multiselect id="fieldTitleCompany"
                       v-model="escrow.data.titleCompany"
                       :allow-empty="false"
                       :class="{selected: escrow.data.titleCompany !== null}"
                       :close-on-select="true"
                       :options="form.titleCompanies"
                       :searchable="true"
                       :show-labels="false"
                       class="full-width"
                       label="label"
                       placeholder="Select Title"
                       track-by="value">
          </multiselect>
        </div>
      </div>
      <div class="modal-body-container" v-if="ui.form.isLoading === false">
        <h5 class="mb-4">Subject Property Address</h5>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group mb-3 full-width">
              <label class="required" for="loan_escrow_address">Address</label>
              <input id="loan_escrow_address" v-model="escrow.data.address"
                     v-input-filled:value="escrow.data.address"
                     class="form-control full-width"
                     required="required"
                     type="text">
            </div>
          </div>
          <div class="col-md-4 pe-0">
            <div class="form-group">
              <label class="required" for="loan_escrow_city">City</label>
              <input id="loan_escrow_city" v-model="escrow.data.city" v-input-filled:value="escrow.data.city"
                     class="form-control"
                     required="required"
                     type="text">
            </div>
          </div>
          <div class="col-md-4 pe-0">
            <div class="form-group">
              <label class="required" for="loan_escrow_state">State</label>
              <multiselect id="loan_escrow_state"
                           v-model="escrow.data.state"
                           :allow-empty="false"
                           :class="{selected: escrow.data.state !== null}"
                           :close-on-select="true"
                           :options="form.states"
                           :searchable="true"
                           :show-labels="false"
                           class="full-width"
                           placeholder="Select State">
              </multiselect>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="required" for="loan_escrow_zip">Zip</label>
              <input id="loan_escrow_zip"
                     v-model="escrow.data.zip"
                     v-input-filled:value="escrow.data.zip"
                     class="form-control"
                     required="required"
                     type="text">
            </div>
          </div>
        </div>
      </div>

      <div v-if="!loan.isRefi" class="modal-body-container">
        <div class="form-group mb-3">
          <label class="required" for="loan_escrow_purchase_price">Purchase Price</label>
          <input id="loan_escrow_purchase_price"
                 v-model="escrow.data.purchase.price"
                 v-input-filled:value="escrow.data.purchase.price"
                 class="form-control"
                 required="required"
                 type="number">
        </div>
        <h5 class="mb-4">Realtor</h5>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-3 full-width">
              <label class="required" for="loan_escrow_realtor_sellingAgent">
                Selling agent
              </label>
              <input id="loan_escrow_realtor_sellingAgent" v-model="escrow.data.purchase.realtor.sellingAgent"
                     v-input-filled:value="escrow.data.purchase.realtor.sellingAgent"
                     class="form-control full-width" required="required"
                     type="text">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-3 full-width">
              <label class="required" for="loan_escrow_realtor_name">Name</label>
              <input id="loan_escrow_realtor_name"
                     v-model="escrow.data.purchase.realtor.name"
                     class="form-control full-width"
                     v-input-filled:value="escrow.data.purchase.realtor.name"
                     required="required"
                     type="text">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-3 full-width">
              <label class="required" for="loan_escrow_realtor_brokerage">Brokerage</label>
              <input id="loan_escrow_realtor_brokerage"
                     v-model="escrow.data.purchase.realtor.brokerage" class="form-control full-width"
                     v-input-filled:value="escrow.data.purchase.realtor.brokerage"
                     required="required" type="text">
            </div>

          </div>
          <div class="col-md-6">
            <div class="form-group mb-3 full-width">
              <label class="required" for="loan_escrow_realtor_phone">Phone</label>
              <input id="loan_escrow_realtor_phone"
                     v-model="escrow.data.purchase.realtor.phone"
                     class="phone-mask-field form-control full-width"
                     v-input-filled:value="escrow.data.purchase.realtor.phone"
                     maxlength="12"
                     required="required"
                     type="text">
            </div>
          </div>
        </div>
        <h5 class="mb-4">Listing agent</h5>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group full-width">
              <label for="loan_escrow_listingAgent_name">Name</label>
              <input id="loan_escrow_listingAgent_name"
                     v-model="escrow.data.purchase.listingAgent.name"
                     v-input-filled:value="escrow.data.purchase.listingAgent.name"
                     class="form-control full-width"
                     type="text">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group full-width">
              <label for="loan_escrow_listingAgent_brokerage">Brokerage</label>
              <input id="loan_escrow_listingAgent_brokerage"
                     v-model="escrow.data.purchase.listingAgent.brokerage" class="form-control full-width"
                     v-input-filled:value="escrow.data.purchase.listingAgent.brokerage"
                     type="text">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group full-width">
              <label for="loan_escrow_listingAgent_phone">Phone</label>
              <input id="loan_escrow_listingAgent_phone"
                     v-model="escrow.data.purchase.listingAgent.phone"
                     v-input-filled:value="escrow.data.purchase.listingAgent.phone"
                     class="phone-mask-field form-control full-width"
                     maxlength="12" type="text">
            </div>
          </div>
        </div>
      </div>

      <div v-if="loan.isRefi" class="modal-body-container border-bottom-0 mb-0">
        <div class="form-group mb-3">
          <label class="required" for="loan_escrow_estimated_value">Estimated Value</label>
          <input id="loan_escrow_estimated_value"
                 v-model="escrow.data.refi.estimatedValue"
                 class="form-control"
                 v-input-filled:value="escrow.data.refi.estimatedValue"
                 required="required"
                 type="number">
        </div>
      </div>

      <div class="modal-body-container border-bottom-0">
        <div class="row">
          <div class="col-md-8">
            <div class="form-group full-width">
              <label class="required full-width" for="loan_escrow_exact_vesting">Exact Vesting</label>
              <input id="loan_escrow_exact_vesting"
                     v-model="escrow.data.exactVesting"
                     v-input-filled:value="escrow.data.exactVesting"
                     class="form-control" required="required"
                     type="text">
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group full-width d-grid">
              <label class="d-block text-white">.</label>
              <button v-tippy :class="{'btn-primary': escrow.data.remindAboutVesting, 'btn-outline-primary': !escrow.data.remindAboutVesting}"
                      class="btn w-100 remind-me-btn"
                      content="Remind me about vesting in 3 days"
                      @click="escrow.data.remindAboutVesting = !escrow.data.remindAboutVesting">
                <span v-if="!escrow.data.remindAboutVesting">
                  Remind me
                </span>
                <span v-else>
                  We'll notify you
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <ValidationErrorsList :error="ui.form.error" :errors="ui.form.errors" :isError="ui.form.isError"/>

    </div>
    <div class="modal-footer d-flex justify-content-start">
      <button class="btn btn-primary submit" @click="submitForm">
        <span v-if="ui.form.isLoading" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
        <span v-if="!ui.form.isLoading">Submit</span>
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ValidationErrorsList from "../../../components/form/ValidationErrorsList";
import PerfectScrollbar from 'perfect-scrollbar'

export default {
  name: "OpenEscrowModal",
  components: {ValidationErrorsList, Multiselect},
  props: {loan: Object, form: Object, subjectProperty: Object},
  data() {
    return {
      escrow: {
        data: {
          isRefi: this.loan.isRefi,
          loanNumber: this.loan.loanNumber,
          escrowCompany: null,
          titleCompany: null,
          address: null,
          city: null,
          state: null,
          zip: null,
          remindAboutVesting: false,
          refi: {estimatedValue: null},
          purchase: {
            price: null,
            realtor: {sellingAgent: null, name: null, brokerage: null, phone: null},
            listingAgent: {name: null, brokerage: null, phone: null},
          },
          exactVesting: null,
        }
      },
      ui: {
        form: {isLoading: false, isSuccess: false, isError: false, errors: [], error: '', ps: null}
      }
    }
  },
  methods: {
    submitForm() {
      this.ui.form.errors = []
      this.ui.form.error = ''
      this.ui.form.isError = false
      this.ui.form.isSuccess = false
      this.ui.form.isLoading = true

      let data = {
        ...this.escrow.data,
        escrowCompany: this.escrow.data.escrowCompany?.value,
        titleCompany: this.escrow.data.titleCompany?.value,
      }

      this.$http.post('/api/v1/loans/escrow/open/' + this.loan.id, data)
        .then(() => {
          this.ui.form.isSuccess = true
          this.$emit('escrowOpened')
        })
        .catch((e) => {
          this.ui.form.isError = true
          let {errors, error} = this.getErrorsFromResponse(e.response)

          this.ui.form.errors = errors;
          this.ui.form.error = error

          this.$nextTick(() => {
            this.$el.scrollTop = 1000 + this.$el.scrollHeight;
          });
        })
        .finally(() => {
          this.ui.form.isLoading = false;
        })
    },
    autoFillSubjectProperty() {
      if (typeof this.subjectProperty !== 'undefined') {
        this.escrow.data.address = this.subjectProperty.address ? this.subjectProperty.address : null
        this.escrow.data.city = this.subjectProperty.city ? this.subjectProperty.city : null
        this.escrow.data.state = this.subjectProperty.state ? this.subjectProperty.state : null
        this.escrow.data.zip = this.subjectProperty.zip ? this.subjectProperty.zip : null
      }
    }
  },
  created() {
    this.autoFillSubjectProperty()
  },
  mounted() {
    const scrollbar = document.getElementById('modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar, {
      useBothWheelAxes: false,
      suppressScrollX: true
    });
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/vars';

#modal-body-scroll {
  position: relative;
  height: calc(100% - 50px);
  margin-bottom: 20px;
}

.modal-body-container::v-deep .multiselect {
  &.selected {
    .multiselect__tags {
      background-color: #F3F3F4;
    }

    .multiselect__single {
      background-color: #F3F3F4;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
    }

    input {
      background-color: #F3F3F4;
    }
  }

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__tags {
    padding: 12px 40px 0 15px;
    transition: all .3s;

    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__select {
    &:before {
      top: 17%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }

  &__single {
    color: rgba(0, 0, 0, 0.46);
    margin-bottom: 0;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }

  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }

    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }

  &__placeholder {
    padding: 0;
  }
}

.escrow-document-wrapper {
  padding-bottom: 20px;
  border-bottom: 1px solid #E7E8E8;
  margin-bottom: 0;
}

.remind-me-btn {
  height: $input-height;
  max-height: $input-height;
}
</style>
